import styles from "./modalApplicationPartnershipMobile.module.scss";
import { TextInput } from "../../textInput/textInput";
import { Button } from "../../button/button";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { DropdownPicker } from "../../dropdownPicker/dropdownPicker";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PARTNERSHIP_APPLICATION_SCHEMA,
  useForm,
} from "../../../utils/applications";
import { useRequest } from "../../../utils/api";
import { useEffect, useState } from "react";

export const ModalApplicationPartnershipMobile = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [buttonTitle, setButtonTitle] = useState<string>("Отправить");

  const { data, errors, setters, isValidationPassed } = useForm(
    PARTNERSHIP_APPLICATION_SCHEMA
  );
  const inputError = Object.keys(errors).length > 0;

  const { applyRequest, response, error } = useRequest();

  useEffect(() => {
    if (response?.status === 200) {
      setButtonTitle("Отправлено!");
      setDisableButton(true);
    } else {
      setButtonTitle("Отправить");
      setDisableButton(false);
    }
  }, [response, error]);

  const onApply = () => {
    if (isValidationPassed()) {
      setDisableButton(true);
      setButtonTitle("Отправка...");
      applyRequest(
        {
          name: data.name,
          phone: data.phone,
          company: data.company,
          companyClass: data.activity,
          merchandise: data.store,
          email: data.email,
          comment: data.comment,
        },
        "partners"
      );
    }
  };

  const property = [
    "Легковой транспорт",
    "Легковой коммерческий транспорт",
    "Коммерческий транспорт",
    "Грузовой транспорт",
    "Оборудование",
  ];

  const terms =
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.";

  return (
    <CommonModalGreenMobile
      buttonTitle={"Стать партнёром"}
      title={"Всегда открыты к новым партнёрствам"}
      onClose={onClose}
    >
      <TextInput
        value={data.name}
        onChange={setters.name}
        error={Boolean(errors.name)}
        dark
        placeholder={"Имя*"}
        type={"name"}
      />
      <TextInput
        value={data.phone}
        onChange={setters.phone}
        error={Boolean(errors.phone)}
        dark
        placeholder={"Телефон*"}
        type={"phone"}
      />
      <TextInput
        value={data.email}
        onChange={setters.email}
        error={Boolean(errors.email)}
        dark
        placeholder={"Электронная почта*"}
      />
      <TextInput
        value={data.company}
        onChange={setters.company}
        dark
        placeholder={"Название организации"}
      />
      <TextInput
        value={data.activity}
        onChange={setters.activity}
        dark
        placeholder={"Тип деятельности"}
      />
      <DropdownPicker
        value={data.store}
        setValue={setters.store}
        options={property}
        style={"inputField"}
        title="Реализуемое имущество"
      />
      <TextInput
        value={data.comment}
        onChange={setters.comment}
        dark
        placeholder={"Комментарий"}
      />
      <div
        className={[
          styles.terms,
          styles.terms__input,
          (inputError && styles.error) || "",
        ].join(" ")}
      >
        * — обязательное поле для заполнения
      </div>
      <div
        onClick={() =>
          navigate("/privacy-policy", {
            state: { previousLocation: location },
          })
        }
        className={styles.terms}
      >
        {terms}
      </div>
      <Button
        title={buttonTitle}
        size={"big"}
        className={styles.button}
        color={"black"}
        onClick={onApply}
        disabled={disableButton}
      />
    </CommonModalGreenMobile>
  );
};
