import { TextInput } from "../../textInput/textInput";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalApplicationProgram.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

import {
  BASE_APPLICATION_SCHEMA,
  CITIES,
  useForm,
} from "../../../utils/applications";
import { useContext, useEffect, useState } from "react";
import { CalculatorContext } from "../../../pages/template/template";
import { useRequest, useGetRequest } from "../../../utils/api";
import { CityInput } from "../../cityInput/cityInput";

interface ProgramInfo {
  type: string;
  title: string;
}

interface ProgramData {
  backgroundImage: string;
  type: string;
  content: {
    title: string;
    buttonlabel: string;
    descriptionlines: string[];
  }[];
}

export const extractProgramTitles = (data?: ProgramData[]): ProgramInfo[] => {
  return (
    data?.map((item) => ({
      type: item.type,
      title: item.content[0].title,
    })) || []
  );
};

export const findTitleByType = (
  type: string,
  extractedTitles: ProgramInfo[]
): string | undefined => {
  const program = extractedTitles.find((item) => item.type === type);
  return program ? program.title : "Неизвестная программа";
};

export const findPrograms = (blocks: any[]): ProgramData[] | undefined => {
  if (!blocks) return;
  const programsBlock = blocks.find((block) => block.key === "programs");
  return programsBlock?.content?.programs;
};

export const ModalApplicationProgram = ({
  isOpen,
  onClose,
  addCalculation = false,
}: {
  isOpen: boolean;
  onClose?: () => void;
  addCalculation?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [buttonTitle, setButtonTitle] = useState<string>("Отправить");

  const terms =
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.";

  const { data, errors, setters, isValidationPassed } = useForm(
    BASE_APPLICATION_SCHEMA
  );

  const { GetRequestData, getRequest } = useGetRequest();

  useEffect(() => getRequest("main"), []);

  const mainPageData = GetRequestData;

  const inputError = Object.keys(errors).length > 0;

  const { calculation } = useContext(CalculatorContext);

  const programType = location.pathname.slice(9);

  const programs = findPrograms(mainPageData?.blocks);

  const extractedTitles = extractProgramTitles(programs);

  const foundTitle = findTitleByType(programType, extractedTitles);

  const { applyRequest, response, error } = useRequest();

  useEffect(() => {
    if (response?.status === 200) {
      setButtonTitle("Отправлено!");
      setDisableButton(true);
    } else {
      setButtonTitle("Отправить");
      setDisableButton(false);
    }
  }, [response, error]);

  const onApply = () => {
    if (isValidationPassed()) {
      setDisableButton(true);
      setButtonTitle("Отправка...");
      if (location.pathname === "/") {
        if (addCalculation) {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              calculation,
            },
            "main"
          );
        } else {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
            },
            "main"
          );
        }
      } else if (location.pathname.indexOf("program") === 1) {
        if (addCalculation) {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              type: foundTitle,
              calculation,
            },
            "program"
          );
        } else {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              type: foundTitle,
            },
            "program"
          );
        }
      }
    }
  };

  return (
    <CommonModalGreen
      onClose={onClose}
      buttonTitle={buttonTitle}
      disableButton={disableButton}
      isOpen={isOpen}
      title={"Оставить заявку"}
      modalClassName={styles.modal}
      titleClassName={styles.title}
      buttonClassName={styles.button__small}
      onButtonClick={onApply}
      terms={terms}
      inputError={inputError}
      onTermsClick={() =>
        navigate("/privacy-policy", { state: { previousLocation: location } })
      }
    >
      <TextInput
        value={data.name}
        onChange={setters.name}
        error={Boolean(errors.name)}
        className={styles.content}
        dark
        placeholder={"Имя*"}
        type={"name"}
      />
      <TextInput
        value={data.phone}
        onChange={setters.phone}
        error={Boolean(errors.phone)}
        className={styles.content}
        dark
        placeholder={"Телефон*"}
        type={"phone"}
      />
      <CityInput
        value={data.city}
        setValue={setters.city}
        error={Boolean(errors.city)}
        title={"Город*"}
        options={CITIES}
        className={styles.content}
        style={"inputField"}
      />
      <TextInput
        value={data.email}
        onChange={setters.email}
        error={Boolean(errors.email)}
        className={styles.content}
        dark
        placeholder={"Электронная почта*"}
      />
      <TextInput
        value={data.company}
        onChange={setters.company}
        className={[styles.content__last].join(" ")}
        dark
        placeholder={"ИНН организации"}
        type={"decimals"}
      />
    </CommonModalGreen>
  );
};
