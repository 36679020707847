import styles from "./textInput.module.scss";
import { CSSProperties } from "react";

export const TextInput = (
  {
    className,
    children,
    placeholder,
    dark = false,
    value,
    onChange,
    error = false,
    errorClassName,
    type = "any",
    style,
  }: {
    className?: string;
    children?: any;
    placeholder?: string;
    dark?: boolean;
    value?: string;
    onChange: ( it: string ) => void;
    error?: boolean;
    errorClassName?: string;
    type?: "any" | "decimals" | "letters" | "name" | "city" | "phone";
    style?: CSSProperties
  } ) => {
  const capitalizeFirstLetter = ( str: string ) => {
    return str.charAt( 0 ).toUpperCase() + str.slice( 1 );
  };

  const onInputValueChange = ( inputValue: string ) => {
    let filteredValue = inputValue;

    if ( inputValue.length === 1 && ( inputValue === "-" || inputValue === " " ) ) {
      filteredValue = "";
    } else if ( type === "decimals" ) {
      filteredValue = inputValue.replace( /[^\d-]/, "" );
    } else if ( type === "letters" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я]/, "" );
    } else if ( type === "name" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я\s-]/g, "" );
    } else if ( type === "city" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я\s\d-]/g, "" );
    } else if ( type === "phone" ) {
      filteredValue = inputValue.replace( /[^\d-+()]/, "" );
    }

    filteredValue = capitalizeFirstLetter( filteredValue );
    onChange( filteredValue );
  };

  return (
    <input
      className={ [
        styles.container,
        dark ? styles.container__dark : styles.container__light,
        className,
        ( error && ( errorClassName || styles.container__error ) ) || "",
      ].join( " " ) }
      children={ children }
      placeholder={ placeholder }
      value={ value }
      onChange={ ( it ) => onInputValueChange( it.target.value ) }
      style={ style }
    />
  );
};
