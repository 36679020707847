import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { ObjectString } from "../../../program/types/blockTypes";

interface ClientsEsignPageGuideBlockType {
  title: string;
  columntexts: ObjectString[];
  identifiers: ObjectString[];
}

export const ClientsEsignPageGuideBlock = ({
  titleShown,
  blocksShown,
  mobile = false,
  content,
}: DesktopBlockPropsType & { content: ClientsEsignPageGuideBlockType }) => {
  return (
    <CommonBlock>
      <div className={styles.container}>
        <AnimatedBlock isShown={mobile || titleShown} className={styles.title}>
          {content.title}
        </AnimatedBlock>
        <div className={styles.columnContainer}>
          {content.columntexts.map((_column, index) => (
            <AnimatedBlock
              className={styles.column}
              isShown={mobile || blocksShown}
              latency={index * 300}
            >
              <div className={styles.point}>{(index + 1).toString()}</div>
              {_column.value}
            </AnimatedBlock>
          ))}
        </div>
        <div className={styles.identifiers}>
          {content.identifiers.map((it) => (
            <div className={styles.identifier}>{it.value}</div>
          ))}
        </div>
      </div>
    </CommonBlock>
  );
};
