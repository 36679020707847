export function isHidden( element: HTMLElement ) {
  const elementRect = element.getBoundingClientRect();
  const elementHidesUp = elementRect.top < 0;
  const elementHidesLeft = elementRect.left < 0;
  const elementHidesDown = elementRect.bottom > window.innerHeight;
  const elementHidesRight = elementRect.right > window.innerWidth;
  const elementHides = elementHidesUp || elementHidesLeft || elementHidesDown || elementHidesRight;
  return elementHides;
}

export function isWithoutParent( element: HTMLElement ) {
  const parentRect = element.parentElement?.getBoundingClientRect();
  const childRect = element.getBoundingClientRect();
  // @ts-ignore
  return childRect.right > parentRect.right || childRect.left < parentRect.left;
}

export const firstVisibleChildElement = ( element: HTMLElement ): HTMLElement | undefined => {
  const child = element.nextElementSibling?.children || [];
  for ( let i = 0; i < child.length; i++ ) {
    const el = child[ i ];
    let displayStyle = window.getComputedStyle( el ).display;
    if ( displayStyle === 'none' ) {
      continue
    }

    let visibilityStyle = window.getComputedStyle( el ).visibility;
    if ( visibilityStyle === 'hidden' ) {
      continue
    }

    //@ts-ignore
    let parentDisplayStyle = window.getComputedStyle( el.parentNode ).display;
    if ( parentDisplayStyle === 'none' ) {
      continue
    }

    //@ts-ignore
    let parentVisibilityStyle = window.getComputedStyle( el.parentNode ).visibility;
    if ( parentVisibilityStyle === 'hidden' ) {
      continue
    }

    return el as HTMLElement;
  }
}

export const scrollToElement = ( element: HTMLElement ) => {
  if ( !element ) return;
  let top = element.offsetTop - 10;

  const el = firstVisibleChildElement( element );

  //@ts-ignore
  if ( el.offsetTop < 100 ) {
    //@ts-ignore
    top -= 100 - el.offsetTop;
  }

  if ( document.querySelector( '[data-tabs]' ) && element.getAttribute( 'id' ) !== 'documents' ) {
    top -= 80;
  }

  document.documentElement.scrollTo( {
    behavior: "smooth",
    top: top,
  } );
}

export const scrollToElementByIndex = ( index: number ) => {
  const nodes = document.querySelectorAll( '[data-key]' );

  scrollToElement( nodes[ index ] as HTMLElement );
}

export const scrollToNextElement = () => {
  const nodes = document.querySelectorAll( '[data-key]' );

  let sel = 0;
  let top = Math.abs( nodes[ 0 ]?.getBoundingClientRect().top );

  for ( let i = 0; i < nodes.length; i++ ) {
    const el = nodes[ i ];
    let t = Math.abs( el.getBoundingClientRect().top );
    if ( top > t ) {
      sel = i;
      top = t;
    }
  }

  scrollToElement( nodes[ sel + 1 ] as HTMLElement );
}

export const scrollToBackElement = () => {
  const nodes = document.querySelectorAll( '[data-key]' );
  let sel = 0;
  let top = Math.abs( nodes[ 0 ]?.getBoundingClientRect().top );

  for ( let i = 0; i < nodes.length; i++ ) {
    const el = nodes[ i ];
    let t = Math.abs( el.getBoundingClientRect().top );
    if ( top > t ) {
      sel = i;
      top = t;
    }
  }
  scrollToElement( nodes[ sel - 1 ] as HTMLElement );
}
