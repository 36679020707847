import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { LazyImage } from "../../../../components/slider/LazyImage";

interface LeasingItemType {
  icon: string;
  title: string;
  text: string;
}

interface AboutUsPageLeasingBlockType {
  title: string;
  subtitle: string;
  items: LeasingItemType[];
}

export const AboutUsPageLeasingBlock = ({
  titleShown,
  blocksShown,
  mobile = false,
  content,
}: DesktopBlockPropsType & { content: AboutUsPageLeasingBlockType }) => {
  return (
    <CommonBlock>
      <div className={styles.container}>
        <AnimatedBlock isShown={mobile || titleShown}>
          <>
            <div className={styles.title}>{content.title}</div>
            <div className={styles.subtitle}>{content.subtitle}</div>
          </>
        </AnimatedBlock>

        <div className={styles.itemContainer}>
          {content.items.map((_item, index) => (
            <>
              {index !== 0 && (
                <AnimatedBlock
                  className={styles.animatedItem}
                  isShown={mobile || blocksShown}
                  latency={index * 300}
                >
                  <div className={styles.item__delimiter} />
                </AnimatedBlock>
              )}

              <AnimatedBlock
                className={styles.animatedItem}
                isShown={mobile || blocksShown}
                latency={index * 300}
              >
                <div
                  className={[styles.item, styles["item_" + (index + 1)]].join(
                    " "
                  )}
                >
                  <LazyImage image={_item.icon} className={styles.item__icon} />
                  <div className={styles.item__title}>{_item.title}</div>
                  <div className={styles.item__text}>{_item.text}</div>
                </div>
              </AnimatedBlock>
            </>
          ))}
        </div>
      </div>
    </CommonBlock>
  );
};
