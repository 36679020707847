import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { Button } from "../../../../components/button/button";
import { AnimatedScrollArrow } from "../../../../components/animatedScrollArrow/animatedScrollArrow";

interface ProgramPageMainBlockContentType {
  title: string;
  subtitle?: string;
  description?: string;
  bigdescription?: string;
  applicationbutton?: string;
  scrolltip?: boolean;
}

export const ProgramPageMainBlock = ({
  content,
  onApplicationButtonPress,
}: DesktopBlockPropsType & { content: ProgramPageMainBlockContentType }) => {
  return (
    <CommonBlock>
      <div className={styles.container}>
        <div className={styles.title}>{content.title}</div>
        {content.subtitle && (
          <div className={styles.subTitle}>{content.subtitle}</div>
        )}
        {content.description && (
          <div className={styles.description}>{content.description}</div>
        )}
        {content.applicationbutton && (
          <Button
            onClick={onApplicationButtonPress}
            className={styles.button}
            title={content.applicationbutton}
          />
        )}
        {content.scrolltip && (
          <div className={styles.animatedArrow}>
            <AnimatedScrollArrow />
          </div>
        )}
        {content.bigdescription && (
          <div className={styles.bigdescription}>{content.bigdescription}</div>
        )}
      </div>
    </CommonBlock>
  );
};
