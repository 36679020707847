import { CSSProperties, useMemo } from "react";
import styles from "./closeButton.module.scss";
import x from "../../assets/icons/x.svg";

export const CloseButton = ({
  className,
  disabled = false,
  onClick,
  square = false,
}: {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  square?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={[
        styles.container,
        className,
        disabled && styles.container__disabled,
        square && styles.container__square,
      ].join(" ")}
    >
      <img src={x} />
    </div>
  );
};
