import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { ObjectString } from "../../types/blockTypes";

interface ProgramPageDocumentsBlockContentType {
  title: string;
  documentlist: ObjectString[];
}

export const ProgramPageDocumentsBlock = ({
  blocksShown,
  content,
  mobile,
}: DesktopBlockPropsType & {
  content: ProgramPageDocumentsBlockContentType;
}) => {
  return (
    <CommonBlock>
      <div className={styles.mainContainer}>
        <AnimatedBlock isShown={mobile || blocksShown} className={styles.title}>
          {content.title}
        </AnimatedBlock>
        <AnimatedBlock
          latency={300}
          isShown={mobile || blocksShown}
          className={styles.column}
        >
          {content.documentlist
            .slice(0, content.documentlist.length / 2)
            .map((_item) => (
              <li className={styles.description}>{_item.value}</li>
            ))}
        </AnimatedBlock>
        <AnimatedBlock
          latency={300}
          isShown={mobile || blocksShown}
          className={styles.column}
        >
          {content.documentlist
            .slice(content.documentlist.length / 2)
            .map((_item) => (
              <li className={styles.description}>{_item.value}</li>
            ))}
        </AnimatedBlock>
      </div>
    </CommonBlock>
  );
};
