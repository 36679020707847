import React, { useEffect, useMemo, useRef, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import AboutUsBackground from "../../../../assets/images/about_us_page_about_us_block.png";
import { UserCard } from "../../../../components/userCard/userCard";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useCalculatedOffset } from "../../../../utils/calcScale";
import { useIsMobile } from "../../../../utils/common";
import { horizontalPadding } from "../../../../utils/common";
import { useCurrentPageIdx } from "../../../../utils/useCurrentPageIdx";

interface StaffType {
  name: string;
  duty: string;
  avatar: string;
}

interface AboutUsPageManagementBlockType {
  title: string;
  staff: StaffType[];
}

export const AboutUsPageManagementBlock = ({
  mobile = false,
  blocksShown,
  titleShown,
  content,
}: DesktopBlockPropsType & { content: AboutUsPageManagementBlockType }) => {
  const isMobile = useIsMobile();

  const _offset = useCalculatedOffset();
  const offset = isMobile ? 0 : _offset;
  const shadowDelay =
    mobile || typeof blocksShown === "boolean"
      ? blocksShown
      : (blocksShown || 0) + 0.25;

  const containerRef = useRef<HTMLDivElement>(null);

  const [scroll, setScroll] = useState(0);

  return (
    <CommonBlock fullWidth>
      <div className={styles.container}>
        <div style={{ paddingLeft: offset }}>
          <AnimatedBlock
            isShown={mobile || titleShown}
            className={styles.title}
          >
            {content.title}
          </AnimatedBlock>
        </div>

        <div
          className={styles.cardContainer}
          style={{ paddingLeft: offset, paddingRight: offset }}
          ref={containerRef}
          onScroll={(e) => {
            setScroll(e.currentTarget.scrollLeft);
          }}
        >
          {content.staff.map((_user) => (
            <AnimatedBlock
              isShown={mobile || blocksShown}
              className={styles.userCard}
            >
              <UserCard user={_user} />
            </AnimatedBlock>
          ))}
        </div>
      </div>

      <AnimatedBlock
        reversable
        className={[styles.shadow, styles.shadow_right].join(" ")}
        isShown={shadowDelay}
      />
      <AnimatedBlock
        reversable
        className={[
          styles.shadow,
          styles.shadow_left,
          scroll < 10 && styles.shadow_hidden,
        ].join(" ")}
        isShown={shadowDelay}
      />

      {/*<div className={styles.backgroundWrapper}>*/}
      {/*  <img src={AboutUsBackground} className={styles.background} />*/}
      {/*  <div className={styles.overlay} />*/}
      {/*</div>*/}
    </CommonBlock>
  );
};
