import { TextInput } from "../../textInput/textInput";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalApplicationPartnership.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownPicker } from "../../dropdownPicker/dropdownPicker";
import {
  PARTNERSHIP_APPLICATION_SCHEMA,
  useForm,
} from "../../../utils/applications";
import { useRequest } from "../../../utils/api";
import { useEffect, useState } from "react";

export const ModalApplicationPartnership = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const navigate = useNavigate();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [buttonTitle, setButtonTitle] = useState<string>("Отправить");

  const location = useLocation();

  const { data, errors, setters, isValidationPassed } = useForm(
    PARTNERSHIP_APPLICATION_SCHEMA
  );
  const inputError = Object.keys(errors).length > 0;

  const { applyRequest, response, error } = useRequest();

  useEffect(() => {
    if (response?.status === 200) {
      setButtonTitle("Отправлено!");
      setDisableButton(true);
    } else {
      setButtonTitle("Отправить");
      setDisableButton(false);
    }
  }, [response, error]);

  const onApply = () => {
    if (isValidationPassed()) {
      setDisableButton(true);
      setButtonTitle("Отправка...");
      applyRequest(
        {
          name: data.name,
          phone: data.phone,
          company: data.company,
          companyClass: data.activity,
          merchandise: data.store,
          email: data.email,
          comment: data.comment,
        },
        "partners"
      );
    }
  };

  const terms =
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.";

  const property = [
    "Легковой транспорт",
    "Легковой коммерческий транспорт",
    "Коммерческий транспорт",
    "Грузовой транспорт",
    "Оборудование",
  ];

  return (
    <CommonModalGreen
      onClose={onClose}
      buttonTitle={buttonTitle}
      isOpen={isOpen}
      title={"Всегда открыты к новым партнёрствам"}
      modalClassName={styles.modal}
      titleClassName={styles.title}
      buttonClassName={styles.button__small}
      terms={terms}
      inputError={inputError}
      onButtonClick={onApply}
      disableButton={disableButton}
      onTermsClick={() =>
        navigate("/privacy-policy", { state: { previousLocation: location } })
      }
    >
      <TextInput
        value={data.name}
        onChange={setters.name}
        error={Boolean(errors.name)}
        className={styles.content}
        dark
        placeholder={"Имя*"}
        type={"name"}
      />
      <TextInput
        value={data.phone}
        onChange={setters.phone}
        error={Boolean(errors.phone)}
        className={styles.content}
        dark
        placeholder={"Телефон*"}
        type={"phone"}
      />
      <TextInput
        value={data.email}
        onChange={setters.email}
        error={Boolean(errors.email)}
        className={styles.content}
        dark
        placeholder={"Электронная почта*"}
      />
      <TextInput
        value={data.company}
        onChange={setters.company}
        className={[styles.content].join(" ")}
        dark
        placeholder={"Название организации"}
      />
      <TextInput
        value={data.activity}
        onChange={setters.activity}
        className={[styles.content].join(" ")}
        dark
        placeholder={"Тип деятельности"}
      />
      <DropdownPicker
        value={data.store}
        setValue={setters.store}
        options={property}
        className={styles.content}
        style={"inputField"}
        title="Реализуемое имущество"
      />
      <TextInput
        value={data.comment}
        onChange={setters.comment}
        className={[styles.content, styles.content__last].join(" ")}
        dark
        placeholder={"Комментарий"}
      />
    </CommonModalGreen>
  );
};
