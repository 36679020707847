import React, { ReactNode } from "react";
import styles from "./headerMobile.module.scss";
import logoIcon from "../../assets/icons/logo.svg";
import { Button } from "../button/button";
import { MenuType } from "../../pages/program/types/blockTypes";

export const HeaderMobile = ({
  ButtonContainer,
  short = false,
  content,
}: {
  ButtonContainer?: ReactNode;
  short?: boolean;
  content: MenuType;
}) => {
  return (
    <div className={styles.fixedContainer}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div
            className={[
              styles.topContainer,
              short ? styles.topContainer_short : "",
            ].join(" ")}
          >
            <img src={logoIcon} className={styles.logo} />

            {content.loginbutton.isshown && (
              <Button
                className={styles.enterButton}
                size={"average"}
                color={"white"}
                title={content.loginbutton.title}
                to={content.loginbutton.to}
              />
            )}
          </div>

          {ButtonContainer}
        </div>
        {/*<div className={styles.gradient} />*/}
      </div>
    </div>
  );
};
