import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AdvantageCard } from "../../../../components/advantageCard/advantageCard";
import { useCalculatedScale } from "../../../../utils/calcScale";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";

interface Benefit {
  title: string;
  description: string;
  backgroundimage: any[];
  icon: any[];
}

interface OptionsPageBenefitsBlockType {
  title: string;
  benefits: Array<Benefit>;
}

export const OptionsPageBenefitsBlock = ({
  mobile,
  titleShown,
  blocksShown,
  content,
}: DesktopBlockPropsType & { content: OptionsPageBenefitsBlockType }) => {
  const scale = useCalculatedScale(() => {
    const cardWidth = 250;
    return (
      cardWidth * content.benefits.length + 24 * (content.benefits.length - 1)
    );
  });

  return (
    <CommonBlock>
      <div className={styles.mainContainer}>
        <AnimatedBlock isShown={mobile || titleShown} className={styles.title}>
          {content.title}
        </AnimatedBlock>
        <div
          style={{
            transform: `scale(${Math.min(scale, 1.5)})`,
          }}
          className={styles.cardsContainer}
        >
          {content.benefits.map((it, index) => (
            <AnimatedBlock
              className={styles.card}
              latency={300 * index}
              isShown={mobile || blocksShown}
            >
              <AdvantageCard {...it} />
            </AnimatedBlock>
          ))}
        </div>
      </div>
    </CommonBlock>
  );
};
