import React, {
  createContext,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./cardTransitionWrapper.module.scss";
import { useEventListener } from "usehooks-ts";
import { useIsMobile } from "../../utils/common";

const CARD_SHIFT = 0;

function compoundingSums( arr: number[] ): number[] {
  const result: number[] = [];
  let total = 0;
  for ( const num of arr ) {
    total += num;
    result.push( total );
  }
  return result;
}

export const CardContext = createContext<{ ref: React.ForwardedRef<any> | null }>( { ref: null } );

export const CardTransitionWrapper = React.forwardRef(
  (
    {
      topCardPadding = 125,
      blocks,
      onCardChange,
    }: {
      topCardPadding?: number;
      blocks: any[];
      onCardChange?: ( idx: number ) => void;
    },
    ref
  ) => {
    const isMobile = useIsMobile();

    const BlockRefs = useRef<( HTMLDivElement | null )[]>(
      Array( blocks.length ).fill( null )
    );

    const [ heightArray, setHeightArray ] = useState<number[]>( [] );
    const [ compoundHeightArray, setCompoundHeightArray ] = useState<number[]>(
      []
    );

    const wrapperStyle = useMemo(
      () => ( { height: compoundHeightArray[ compoundHeightArray.length - 1 ] } ),
      [ compoundHeightArray ]
    );

    useEffect( () => {
      // if ( isMobile ) {
      //   return;
      // }

      let animationFrameId: any = null;

      const handleResize = () => {
        console.log( "handleResize" );
        cancelAnimationFrame( animationFrameId );
        animationFrameId = requestAnimationFrame( () => {
          const cardHeight = window.outerHeight - topCardPadding;
          const commonHeight = `${ cardHeight }px`;
          const newHeightArray = BlockRefs.current.map( ( _ref, index ) => {
            if ( _ref ) {
              _ref.style.height = index === 0 ? "100vh" : commonHeight;
              return _ref.scrollHeight || 0;
            }
            return 0;
          } );
          const newCompoundHeightArray = compoundingSums( newHeightArray );
          console.log( "setting", newHeightArray, newCompoundHeightArray );
          setHeightArray( newHeightArray );
          setCompoundHeightArray( newCompoundHeightArray );
        } );
      };

      handleResize();

      window.addEventListener( "resize", handleResize );

      return () => {
        window.removeEventListener( "resize", handleResize );
        cancelAnimationFrame( animationFrameId );
      };
    }, [ blocks, topCardPadding ] );

    const scrollFn = useCallback( () => {
      // if ( isMobile ) {
      //   return;
      // }

      const { scrollTop } = document.documentElement;

      const currentCardIdx = compoundHeightArray.findIndex(
        ( height ) => height - scrollTop - topCardPadding - 50 > 0
      );
      onCardChange?.( currentCardIdx );

      BlockRefs.current.forEach( ( _ref, index ) => {
        if ( _ref ) {
          const prevHeight = compoundHeightArray[ index - 1 ] || 0;
          const offset = scrollTop - prevHeight;
          if ( index > currentCardIdx + 1 ) {
            _ref.style.transform = `translateY(${
              compoundHeightArray[ compoundHeightArray.length - 1 ]
            }px)`;
            return;
          }
          const totalShift = index === 0 ? 0 : topCardPadding + CARD_SHIFT;

          _ref.style.transform = `translateY(${ Math.max(
            prevHeight - scrollTop,
            totalShift
          ) }px)`;

          if ( index < currentCardIdx - 1 ) {
            return;
          }
          _ref.scrollTo( {
            top: offset,
          } );
        }
      } );
    }, [ heightArray, topCardPadding, blocks ] );

    useLayoutEffect( () => {
      console.log( "new scrollFn" );
      scrollFn();
    }, [ scrollFn ] );
    useEventListener( "scroll", scrollFn, undefined, { passive: true } );

    useImperativeHandle( ref, () => ( {
      scrollToIdxCard: ( idx: number ) => {
        document.documentElement.scrollTo( {
          top: compoundHeightArray[ idx - 1 ] || 0,
        } );
      },
      updateHeightContainer: () => {
        const newHeightArray = BlockRefs.current.map(
          ( _ref ) => _ref?.scrollHeight || 0
        );
        setHeightArray( newHeightArray );
        setCompoundHeightArray( compoundingSums( newHeightArray ) );
      },
    } ) );

    const containerRef = useRef<HTMLDivElement>( null );

    console.log( "cardTransitionWrapper rerender" );
    return (
      <CardContext.Provider value={ { ref: ref } }>
        <div className={ styles.mobileBlockContainer }>
          <div style={ wrapperStyle }/>

          <div ref={ containerRef }
               style={ {
                 position: "fixed",
                 top: 0,
                 zIndex: 0,
                 width: "100%",
               } }
               className={ styles.hideScrollbar }
          >
            { blocks.map( ( block, index ) => {
              return (
                <div
                  ref={ ( _ref ) => ( BlockRefs.current[ index ] = _ref ) }
                  className={
                    [ index === 0
                      ? styles.mobileBlockWrapper_firstBlock
                      : styles.mobileBlockWrapper,
                      styles.hideScrollbar
                    ].join( ' ' )
                  }
                >
                  { block }
                </div>
              )
            } ) }
          </div>
        </div>
      </CardContext.Provider>
    );
  }
);
