import styles from "./cityInput.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import chevronUp from "../../assets/icons/chevron-up_gray.svg";
import chevronDown from "../../assets/icons/chevron-down_gray.svg";
import chevronUpWhite from "../../assets/icons/chevron-up.svg";
import chevronDownWhite from "../../assets/icons/chevron-down.svg";

export const CityInput = ({
  options,
  value,
  setValue,
  title,
  className,
  style = "dropdownPicker",
  error = false,
}: {
  options: string[];
  value: string;
  setValue: (a: any) => void;
  title?: string;
  className?: string;
  style?: "dropdownPicker" | "inputField" | "inputFieldForm";
  error?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const onItemClick = (a: any) => {
    setValue(a);
    setTimeout(() => setShowDropdown(false), 200);
  };

  const searchResults = useMemo(() => {
    const terms = value.toLowerCase().replaceAll("ё", "е").trim().split(" ");
    const foundKeys = new Set();
    const foundItems = options.filter((it) => {
      if (foundKeys.has(it)) return false;
      const title = it.toLowerCase().replaceAll("ё", "е");
      const found = terms.reduce(
        (acc, val) => acc && title.includes(val),
        true
      );
      if (found) foundKeys.add(it);
      return found;
    });
    foundItems.push("Другое");
    return foundItems;
  }, [value]);

  useEffect(() => {
    showDropdown && inputRef?.current?.focus();
  }, [inputRef, showDropdown]);

  return (
    <div className={[styles.outterContainer, className].join(" ")}>
      <div
        onClick={() => setShowDropdown(!showDropdown)}
        className={[
          styles.container,
          (showDropdown && styles.container__active) || "",
          (style === "inputField" && styles.container__inputField) || "",
          (style === "inputFieldForm" && styles.container__inputFieldForm) ||
            "",
          (error && styles.container__error) || "",
        ].join(" ")}
      >
        <input
          type={"text"}
          onChange={(text) => setValue(text.target.value)}
          value={value}
          ref={inputRef}
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          placeholder={title}
          className={[
            styles.input,
            (showDropdown && styles.input__active) || "",
            (style === "inputField" && styles.input__inputField) || "",
            (style === "inputFieldForm" && styles.input__inputFieldForm) || "",
            (error && styles.input__error) || "",
          ].join(" ")}
        />
        <img
          src={
            style === "inputFieldForm"
              ? showDropdown
                ? chevronUpWhite
                : chevronDownWhite
              : showDropdown
              ? chevronUp
              : chevronDown
          }
          className={[
            styles.arrow,
            (style === "inputField" && styles.arrow__inputField) || "",
            (style === "inputFieldForm" && styles.arrow__inputFieldForm) || "",
          ].join(" ")}
        />
      </div>
      {showDropdown && (
        <DropdownList
          style={style}
          setValue={onItemClick}
          options={searchResults}
        />
      )}
    </div>
  );
};

const DropdownList = ({
  options,
  setValue,
  style = "dropdownPicker",
}: {
  options: any;
  setValue: (a: any) => void;
  style?: "dropdownPicker" | "inputField" | "inputFieldForm";
}) => {
  return (
    <div
      className={[
        styles.dropdownContainer,
        (!options && styles.dropdownContainer__small) || "",
        (style === "inputField" && styles.dropdownContainer__inputField) || "",
      ].join(" ")}
    >
      {options.map((it: string, index: number) => (
        <Item title={it} onClick={() => setValue(it)} />
      ))}
    </div>
  );
};

const Item = ({
  title,
  onClick,
  className,
}: {
  title: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div onClick={onClick} className={[styles.listItem, className].join(" ")}>
      {title}
    </div>
  );
};
