import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { blockHandler } from "./pages/template/blockHandler";
import { BlockTypes } from "./pages/program/types/blockTypes";

const rootEl = document.getElementById( "root" );
if ( rootEl ) {
  const root = ReactDOM.createRoot(
    document.getElementById( "root" ) as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
} else {
  // @ts-ignore
  window.blockHandler = blockHandler
  const rootRegistry = {}
  // @ts-ignore
  window.rootRegistry = rootRegistry
  // @ts-ignore
  window.renderBeast = ( el: HTMLElement, id: string, block: any ) => {
    console.log( id, block )
    // @ts-ignore
    const root = rootRegistry[ id ] ||= ReactDOM.createRoot( el )
    root.render( <>{ blockHandler( block ) }</> )
  }
  const reactBlocks = [ ...Object.values( BlockTypes ) ]
  // @ts-ignore
  window.reactBlocks = reactBlocks

  // @ts-ignore
  window.onWidgetLoaded?.( { rootRegistry, renderBeast: window.renderBeast, reactBlocks } )
}
