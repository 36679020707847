import React, { useRef, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useNavigate } from "react-router-dom";
import {
  DiscountCard,
  DiscountCardType,
} from "../../../../components/discountCard/discountCard";
import { DiscountsContainer } from "../../../../components/discountsContainer/discountsContainer";

interface ProgramPageDiscountBlockContentType {
  discountsdata: DiscountCardType[];
  descriptiontitle: string;
  descriptionsubtitle: string;
  description: string;
  document: any[];
  title: string;
}

export const ProgramPageDiscountsBlock = ({
  blocksShown,
  content,
  mobile,
}: DesktopBlockPropsType & {
  content: ProgramPageDiscountBlockContentType;
}) => {
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [scroll, setScroll] = useState<number>(0);

  const hideButtonsAndBlur = content.discountsdata.length < 5;

  return (
    <CommonBlock fullWidth style={{ justifyContent: "center" }}>
      <div className={styles.screenMobile}>
        <div className={styles.titleMobile}>{content.title}</div>
        <div className={styles.cardsContainerMobile}>
          {content.discountsdata.map((it, index) => (
            <DiscountCard className={styles.card} {...it} />
          ))}
        </div>
        <div className={styles.descriptionTitle}>
          {content.descriptiontitle}
        </div>
        <div className={styles.descriptionContainer}>
          <div
            className={[styles.description, styles.description__subtitle].join(
              " "
            )}
          >
            {content.descriptionsubtitle}
          </div>
          <div className={styles.description}>{content.description}</div>
          <a
            href={content.document[0].url}
            target="_blank"
            className={styles.description__green}
          >
            {"подробнее >>"}
          </a>
        </div>
      </div>
      <div className={styles.screen}>
        <AnimatedBlock
          className={styles.cardWrapper}
          isShown={mobile || blocksShown}
        >
          <DiscountsContainer
            ref={scrollRef}
            services={content.discountsdata}
          />
          <div className={styles.conditionsContainer}>
            <div className={styles.descriptionTitle}>
              {content.descriptiontitle}
            </div>
            <div className={styles.descriptionContainer}>
              <div
                className={[
                  styles.description,
                  styles.description__subtitle,
                ].join(" ")}
              >
                {content.descriptionsubtitle}
              </div>
              <a
                href={content.document[0].url}
                target="_blank"
                className={styles.description}
              >
                {content.description}
              </a>
            </div>
          </div>
        </AnimatedBlock>
      </div>
    </CommonBlock>
  );
};
