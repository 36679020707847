import React, { ReactNode } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { ObjectString } from "../../../program/types/blockTypes";

export interface DesktopBlockPropsType {
  titleShown?: boolean | number;
  blocksShown?: boolean | number;
  mobile?: boolean;
}

interface AboutUsPageAboutUsBlockType {
  title: string;
  columntexts: ObjectString[];
}

export const AboutUsPageAboutUsBlock = ({
  titleShown,
  blocksShown,
  mobile = false,
  content,
}: DesktopBlockPropsType & { content: AboutUsPageAboutUsBlockType }) => {
  return (
    <CommonBlock>
      <div className={styles.container}>
        <AnimatedBlock isShown={mobile || titleShown} className={styles.title}>
          {content.title}
        </AnimatedBlock>
        <div className={styles.columnContainer}>
          {content.columntexts.map((_column, index) => (
            <AnimatedBlock
              isShown={mobile || blocksShown}
              latency={index * 300}
            >
              <div className={styles.column}>{_column.value}</div>
            </AnimatedBlock>
          ))}
        </div>
      </div>
    </CommonBlock>
  );
};
