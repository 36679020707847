import styles from "./commonModalGreen.module.scss";
import { CommonModalWrapper } from "../../commonModalWrapper/commonModalWrapper";
import { CloseButton } from "../../closeButton/closeButton";
import { Button } from "../../button/button";
import { useEffect, useMemo } from "react";
import { modals } from "../../../utils/modals";

export const CommonModalGreen = (
  {
    isOpen,
    children,
    onClose,
    buttonTitle,
    buttonClassName,
    title,
    titleClassName,
    modalClassName,
    onButtonClick,
    terms,
    rightButton,
    onTermsClick,
    inputError = false,
    disableButton = false,
  }: {
    isOpen: boolean;
    children?: any;
    onClose?: () => void;
    buttonTitle?: string;
    title?: string;
    titleClassName?: string;
    modalClassName?: string;
    buttonClassName?: string;
    onButtonClick?: () => void;
    terms?: string;
    rightButton?: any;
    onTermsClick?: () => void;
    inputError?: boolean;
    disableButton?: boolean;
  }) => {
  const key = useMemo(() => Date.now(), [])

  useEffect(() => {
    modals.keys.push(key);
    const closeOnEsc = (e: any) => {
      if ( key !== modals.keys[modals.keys.length - 1] ) {
        return;
      }

      if ( e.key === "Escape" ) {
        if ( onClose !== undefined ) {
          onClose();
        }
      }
    };
    document.addEventListener("keydown", closeOnEsc);

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
      modals.keys.pop();
    };
  }, []);

  return (
    <CommonModalWrapper onClose={ onClose } isOpen={ isOpen }>
      <div className={ styles.outterContainer }>
        <div className={ [ styles.modal, modalClassName ].join(" ") }>
          <p className={ [ styles.title, titleClassName ].join(" ") }>{ title }</p>
          { children }
          { buttonTitle && (
            <div className={ styles.buttonContainer }>
              <Button
                className={ [ styles.button, buttonClassName ].join(" ") }
                size={ "big" }
                color={ "black" }
                title={ buttonTitle }
                onClick={ onButtonClick }
                disabled={ disableButton }
              />
              { rightButton }
              <div
                className={ [
                  styles.inputWarningText,
                  (inputError && styles.inputWarningText__error) || "",
                ].join(" ") }
              >
                * Обязательное поле для заполнения
              </div>
            </div>
          ) }
          { terms && (
            <p
              onClick={ () => {
                onTermsClick !== undefined && onTermsClick();
              } }
              className={ styles.smallText }
            >
              { terms }
            </p>
          ) }
        </div>
        <CloseButton onClick={ onClose } className={ styles.closeButton }/>
      </div>
    </CommonModalWrapper>
  );
};
