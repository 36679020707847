import React, { useState } from "react";
import { UserCard } from "../../components/userCard/userCard";
import { ProgressBar } from "../../components/progressBar/progressBar";
import styles from "./uiKitPage.module.scss";
import { useIsMobile } from "../../utils/common";
import TeamAvatar1 from "../../assets/images/team_member1.png";
import { LazyImage } from "../../components/slider/LazyImage";

export const UiKitPage = () => {
  const [tab, setTab] = useState(0);

  const isMobile = useIsMobile();

  const option = {
    title: "Кэшбэк",
    descriptionheader:
      "Верните 2% от стоимости имущества по завершению срока договора.",
    description:
      "Эта опция доступна только при соблюдении следующих условий:\n• Срок лизинга не менее 36 месяцев\n• Только легковой транспорт\n• Своевременные платежи\n• Прохождение ТО у официального дилера, аккредитованного\n    лизингодателем",
    combineswith: ["Стандарт", "Экспресс"],
  };

  const image = {
    id: "main_page_for_business.png",
    image: {
      back: "white",
      color: "orange-400",
      cover: false,
      icon: "image",
      ratio: "3/2",
      url: "https://api.finsmart.ru/media/pages/main/f9ca789d13-1719785100/main_page_for_business.png",
      src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw",
      srcset:
        "https://api.finsmart.ru/media/pages/main/f9ca789d13-1719785100/main-page-for-business-38x.png 38w, https://api.finsmart.ru/media/pages/main/f9ca789d13-1719785100/main-page-for-business-76x.png 76w",
    },
    info: "",
    link: "/pages/main/files/main_page_for_business.png",
    sortable: true,
    text: "main_page_for_business.png",
    uuid: "file://kUJm8tR7dAZZF1oq",
    dragText: "(image: main_page_for_business.png)",
    filename: "main_page_for_business.png",
    type: "image",
    url: "https://api.finsmart.ru/media/pages/main/f9ca789d13-1719785100/main_page_for_business.png",
  };

  return (
    <div className="App">
      <div className={styles.screen_container}>
        <div className={styles.screen_blue}>
          <LazyImage image={[image]} />
        </div>

        <div className={styles.screen_mobile}>
          {/* <ModalApplicationPartnership isOpen /> */}
          {/* <CommonModalMobileWrapper isOpen>
            <ModalApplicationPartnershipMobile />
          </CommonModalMobileWrapper> */}
        </div>

        <div className={styles.screen_green}></div>

        <div className={styles.screen}>
          {/* <UserCard
            user={{
              name: "Бубнов Роман",
              duty: "Генеральный директор",
              avatar: TeamAvatar1,
            }}
          /> */}
        </div>
      </div>

      <ProgressBar
        blackCountScreens={[1, 3]}
        pageCount={5}
        className={styles.progress_bar}
      />
    </div>
  );
};
