import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useIsMobile } from "../../../../utils/common";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import documentIcon from "../../../../assets/icons/document-esign-white.svg";
import { ObjectString } from "../../../program/types/blockTypes";

interface ClientsEsignPageDocumentsBlockType {
  title: string;
  documentlist: ObjectString[];
}

export const ClientsEsignPageDocumentsBlock = ({
  titleShown,
  blocksShown,
  mobile = false,
  content,
}: DesktopBlockPropsType & { content: ClientsEsignPageDocumentsBlockType }) => {
  const isMobile = useIsMobile();

  return (
    <CommonBlock>
      <div className={styles.container}>
        <AnimatedBlock isShown={mobile || titleShown} className={styles.title}>
          {content.title}
        </AnimatedBlock>

        <div>
          {content.documentlist.map((it, index) => (
            <AnimatedBlock
              className={styles.item}
              isShown={mobile || blocksShown}
              latency={index * 300}
            >
              <img src={documentIcon} className={styles.item__icon} />
              <span>{it.value}</span>
            </AnimatedBlock>
          ))}
        </div>
      </div>
    </CommonBlock>
  );
};
