import React, { useEffect, useState } from "react";
import { Tabs } from "../../tabs/tabs";
import { TextInput } from "../../textInput/textInput";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalApplicationWithTabs.module.scss";
import { Button } from "../../button/button";
import documentWhite from "../../../assets/icons/document-esign-white.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownPicker } from "../../dropdownPicker/dropdownPicker";
import { APPEAL_APPLICATION_SCHEMA, APPEAL_APPLICATION_SCHEMA_TOPIC, useForm, } from "../../../utils/applications";
import { useRequest } from "../../../utils/api";
import AnimatedChange from "../../AnimatedChange";
import { appealTopics } from "../../../data/appeals";

export const ModalApplicationWithTabs = (
  {
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose?: () => void;
  } ) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [ tab, setTab ] = useState( 0 );

  const [ disableButton, setDisableButton ] = useState<boolean>( false );

  const [ buttonTitle, setButtonTitle ] = useState<string>( "Отправить" );

  const [ file, setFile ] = useState<any>( null );

  const [ fileError, setFileError ] = useState<boolean>( false );

  const { data, errors, setters, isValidationPassed } = useForm(
    tab === 2 ? APPEAL_APPLICATION_SCHEMA_TOPIC : APPEAL_APPLICATION_SCHEMA
  );

  const { applyRequest, response, error } = useRequest();

  useEffect( () => {
    if ( response?.status === 200 ) {
      setButtonTitle( "Отправлено!" );
      setDisableButton( true );
    } else {
      setButtonTitle( "Отправить" );
      setDisableButton( false );
    }
  }, [ response, error ] );

  const inputError =
    !!errors.name ||
    !!errors.phone ||
    ( ( !!errors.topic || fileError ) && tab === 2 );
  const onApply = () => {
    if ( file === null ) {
      setFileError( true );
    } else {
      setFileError( false );
    }
    if ( isValidationPassed() && !( ( fileError || file === null ) && tab === 2 ) ) {
      setDisableButton( true );
      setButtonTitle( "Отправка..." );
      if ( tab === 0 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            inn: data.company,
            comment: data.comment,
          },
          "quality-service"
        );
      } else if ( tab === 1 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            mediaName: data.media,
            comment: data.comment,
          },
          "media"
        );
      } else if ( tab === 2 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            inn: data.company,
            requestType: data.topic,
            comment: data.comment,
          },
          "maintenance",
          file
        );
      }
    }
  };

  const descriptions = [
    "Помогите нам стать лучше",
    "Ответим на любой вопрос",
    "Всегда поможем!",
  ];

  const terms = [
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.",
    "При направлении заявки, не забудьте подписать бумажную версию документа и прикрепить скан.\nФормы заявлений можно взять в разделе «Документы».",
  ];

  const applicationTopics = appealTopics;

  return (
    <CommonModalGreen
      onClose={ onClose }
      buttonTitle={ buttonTitle }
      disableButton={ disableButton }
      onButtonClick={ onApply }
      isOpen={ isOpen }
      title={ "Оставить заявку" }
      modalClassName={ styles.modal }
      titleClassName={ styles.title }
      buttonClassName={ styles.button__small }
      terms={ terms[ tab === 2 ? 1 : 0 ] }
      inputError={ inputError }
      onTermsClick={ () =>
        navigate( "/privacy-policy", { state: { previousLocation: location } } )
      }
      rightButton={
        <div style={ {
          transition: "all .5s",
          maxWidth: tab === 2 ? 240 : 0,
          minWidth: tab === 2 ? 240 : 0,
        } }>
          <Button
            className={ [
              styles.button__small,
              styles.button__small__right,
              ( fileError && styles.button__error ) || "",
            ].join( " " ) }
            size={ "big" }
            color={ "black" }
            file
            title={
              file?.name?.length > 15
                ? file?.name?.slice( 0, 15 ) + "..."
                : file?.name || "Прикрепить документ*"
            }
            onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => {
              setFile( ( e.target?.files && e.target?.files[ 0 ] ) || "" );
              setFileError( false );
            } }
            RightIcon={ <img src={ documentWhite } className={ styles.icon }/> }
            style={ {
              opacity: tab === 2 ? 1 : 0,
              transition: "all .5s",
              position: "absolute"
            } }
          />
        </div>
      }
    >
      <div className={ styles.smallDescription }>{ descriptions[ tab ] }</div>
      <Tabs
        dark
        onSelectTab={ setTab }
        selectedIdx={ tab }
        tabs={ [ "Служба качества", "Пресса", "Сопровождение" ] }
        containerClassName={ [
          styles.tabs,
          // tab === 2 && styles.tabs__lessMargin,
        ].join( " " ) }
      />
      <DropdownPicker
        value={ data.topic }
        setValue={ setters.topic }
        options={ applicationTopics }
        title={ "Выберите тему обращения" }
        error={ errors.topic }
        stylesheet={ {
          transition: "all .5s",
          maxHeight: tab === 2 ? 40 : 0,
          overflow: 'hidden'
        } }
      />
      <TextInput
        value={ data.name }
        onChange={ setters.name }
        className={ styles.content }
        dark
        placeholder={ "Имя*" }
        error={ Boolean( errors.name ) }
        type={ "name" }
      />
      <TextInput
        value={ data.phone }
        onChange={ setters.phone }
        className={ styles.content }
        dark
        placeholder={ "Телефон*" }
        error={ Boolean( errors.phone ) }
        type={ "phone" }
      />

      <AnimatedChange
        duration={ 0.4 }
        showIndex={ tab === 1 ? 0 : 1 }
        opacity={ 0.5 }
      >
        <TextInput
          value={ data.media }
          onChange={ setters.media }
          className={ styles.conten }
          dark
          placeholder={ "Название СМИ" }
        />
        <TextInput
          value={ data.company }
          onChange={ setters.company }
          className={ [ styles.content ].join( " " ) }
          dark
          placeholder={ "ИНН организации" }
          type={ "decimals" }
        />
      </AnimatedChange>

      <AnimatedChange
        duration={ 0.4 }
        showIndex={ tab === 1 ? 0 : 1 }
        opacity={ 0.5 }
      >
        <TextInput
          value={ data.appeal }
          onChange={ setters.appeal }
          className={ [ styles.content, styles.content__last ].join( " " ) }
          dark
          placeholder={ "Обращение" }
        />
        <TextInput
          value={ data.comment }
          onChange={ setters.comment }
          className={ [ styles.content, styles.content__last ].join( " " ) }
          dark
          placeholder={ "Комментарий" }
        />
      </AnimatedChange>
    </CommonModalGreen>
  );
};
